<template>

  <div id="coopCad">
    <h2 class="titulo2">{{titulo}}</h2>
    <div :v-model="status"></div>
    <b-button
      class="btnRight"
      variant="btn btn-warning btn-sg"
      link
      to="/coopList"
    > Voltar</b-button>

    <hr>
    <form
      enctype="multipart/form-data"
      @submit="onSubmit"
      v-if="show"
      class="row g-3"
    >

      <div class="form-row">

        <div class="page-informacoes">
          <h3>Dados Pessoais </h3>
        </div>




        <div class="img_perfil text-center col-lg-12">

          <b-avatar
            :src="form.urlImg1"
            size="6rem"
          ></b-avatar>
          <div class="cad-foto">
            <b-button variant="success" onclick="document.getElementById('uploadFoto').click()">Alterar Foto</b-button>
            <b-form-file v-model="imageData1" plain id="uploadFoto" hidden></b-form-file>
          </div> 

        </div>


        <div class="row row_status_button text-center col-lg-12">

          <div class="col-lg-4 mt-2">
            <b-button
              class="btn_salvar"
              type="submit"
              variant="success"
            >Salvar</b-button>
          </div>

          <div class="col-lg-4">
            <div class="status">
              Status: 
              <span class="badge badge-success">{{form.status}}</span>
            </div>
          </div>

          <div class="col-lg-4 mt-2 text-left">

            <div class="checkbox_gestor">
              <div class="col-lg-12">
                <b-form-checkbox
                  v-model="form.gestor"
                  switch
                  size="lg"
                >GESTOR
                </b-form-checkbox>
              </div>
  
              <div class="col-lg-12">
                <b-form-checkbox
                  v-model="form.checkinManualApp"
                  switch
                  size="lg"
                >Registro manual no APP
                </b-form-checkbox>
              </div>
            </div>
          
          </div>      

        </div>

        

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Nome</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.nome"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Situação </label>
          <b-form-select
            v-model="form.status"
            :options="status"
          ></b-form-select>
        </div>


        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Situação na cooperativa</label>
          <b-form-select
            v-model="form.cooperativa"
            :options="situacaoCooperativa"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Matricula</label>
          <b-form-input
            v-model="form.matricula"
            readonly
          ></b-form-input>
        </div>


        <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> CPF</label>
          <b-form-input
            v-model="form.cpf"
            v-mask="'###.###.###-##'"
          ></b-form-input>
        </div>


        <div class="form-group col-lg-3 ">
          <label><span class="text-danger">*</span> Data Nascimento</label>
          <b-input-group class="mb-3">
          <b-form-input
            id="example-input"
            v-model="form.dataNasc"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
        </b-input-group>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat1"
            v-model="form.dataNasc"
            placeholder="Selecione a data"
            label-no-date-selected="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        

        

        <!-- <div class="upload-form col-lg-6" id="fileImageAtestado">
          <label><span class="text-danger"></span> Imagem Atestado</label>
          <b-form-file   
            v-model="imageData2"
            placeholder="Selecione Atestado"
            drop-placeholder="Arraste..."
            browse-text="Selecione"
          ></b-form-file>


        </div> -->

        

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Documentos Pessoais--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Documentos Pessoais</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Nome da Mãe</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.nomeMae"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Nome da Pai</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.nomePai"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> RG</label>
          <b-form-input
            v-model="form.rg"
            v-mask="'NN.NNN.NNN-N'"
          ></b-form-input>
        </div>

        <!-- <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Digito</label>
          <b-form-input v-model="form.digito"></b-form-input>
        </div> -->

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Data de Expedição</label>
          <b-form-input
            id="datepicker-dateformat2"
            v-model="form.dataExp"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat2"
            v-model="form.dataExp"
            placeholder="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Sexo: </label>
          <b-form-select
            v-model="form.sexo"
            :options="sexo"
          ></b-form-select>
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Funções--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Funções</h3>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 1 </label>
          <b-form-select
            v-model="form.funcao1"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 2 </label>
          <b-form-select
            v-model="form.funcao2"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 3 </label>
          <b-form-select
            v-model="form.funcao3"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 4 </label>
          <b-form-select
            v-model="form.funcao4"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 5 </label>
          <b-form-select
            v-model="form.funcao5"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 6 </label>
          <b-form-select
            v-model="form.funcao6"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 7 </label>
          <b-form-select
            v-model="form.funcao7"
            :options="funcoes"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Função 8 </label>
          <b-form-select
            v-model="form.funcao8"
            :options="funcoes"
          ></b-form-select>
        </div>

        <!-- <b-card
          class="mt-3"
          header="Resultado do Form"
        >
          <pre class="m-0">{{ form }}</pre>
        </b-card> -->


        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Endereço--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Endereço</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> CEP</label>
          <b-form-input
            v-model="form.cep"
            v-mask="'#####-###'"
            @keyup="buscarCep"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Região</label>
          <b-form-select
            class="text-uppercase"
            v-model="form.regiao"
            :options="regioes"
          ></b-form-select>
        </div>



        <div class="form-group col-lg-5">
          <label><span class="text-danger">*</span> Endereço</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.endereco"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-1">
          <label><span class="text-danger">*</span> Numero</label>
          <b-form-input v-model="form.numero"></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Complemento</label>
          <b-form-input v-model="form.complemento"></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Bairro</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.bairro"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-5">
          <label><span class="text-danger">*</span> Cidade</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.cidade"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-1">
          <label><span class="text-danger">*</span> UF</label>
          <b-form-input
            class="text-uppercase"
            v-model="form.uf"
          ></b-form-input>
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Contato--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Contato</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger">*</span> Email</label>
          <b-form-input
            type="email"
            v-model="form.email"
          ></b-form-input>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Telefone</label>
          <b-form-input
            v-model="form.telefone1"
            v-mask="' (##) #####-####'"
          ></b-form-input>
        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Dados Bancários--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Dados Bancários</h3>
        </div>


        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Tipo: </label>
          <b-form-select
            v-model="form.tipoPagto"
            :options="tipoPagto"
          ></b-form-select>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Banco: </label>
          <b-form-select
            v-model="form.banco"
            :options="bancos"
          ></b-form-select>
        </div>

        <!-- <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Banco</label>
          <b-form-input v-model="form.banco"></b-form-input>
        </div> -->

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Agência</label>
          <b-form-input v-model="form.agencia"></b-form-input>
        </div>

        <div class="form-group col-lg-3">
          <label><span class="text-danger"></span> Conta</label>
          <b-form-input v-model="form.conta"></b-form-input>
        </div>

        

        <!-- <div class="form-group col-lg-3">
          <label><span class="text-danger">*</span> Tipo</label>
          <b-form-input v-model="form.tipoPagto"></b-form-input>
        </div> -->

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Atestado--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Atestado</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Data de Emissão</label>
          <b-form-input
            id="datepicker-dateformat3"
            v-model="form.dataEmissao"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat3"
            v-model="form.dataEmissao"
            placeholder="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Validade</label>
          <b-form-input
            id="datepicker-dateformat4"
            v-model="form.validade"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat4"
            v-model="form.validade"
            placeholder="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        <div class="bloco1 col-lg-12">
          <div class="upload-form col-lg-6">
            <b-form-file
              v-model="imageData2"
              placeholder="Selecione Atestado"
              drop-placeholder="Arraste..."
              browse-text="Selecione"
            ></b-form-file>

            <!-- <label><span class="text-danger"></span> Imagem Atestado</label> -->

            <b-img
              id="img2"
              class="foto preview3 mx-3 mt-4"
              :src="form.urlImg2"
            ></b-img>
          </div>

          <!-- <div class="bloco3 col-lg-6">
            <div class="col-lg-5 mt-5">
              <b-form-group label="Atestado">
                <b-form-radio-group
                  v-model="form.atestado"
                  :options="options1"
                  name="radio-inline"
                ></b-form-radio-group>
              </b-form-group>
            </div>

            <div class="form-group  mt-5">
              <label><span class="text-danger">*</span> Motivo: </label>
              <b-form-select
                v-model="form.motivoAtes"
                :options="motivoAtes"
              ></b-form-select>
            </div>
          </div> -->

        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Foto com Uniforme--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Foto com Uniforme</h3>
        </div>

        <!-- <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Data de Emissão</label>
          <b-form-datepicker
            type="date"
            id="datepicker-dateformat5"
            v-model="form.dataEmissao2"
            placeholder="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Validade</label>
          <b-form-datepicker
            type="date"
            id="datepicker-dateformat6"
            v-model="form.validadeUnif"
            placeholder="Selecione a data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker>
        </div> -->

        <div class="bloco1 col-lg-12">
          <div class="upload-form col-lg-6">
            <b-form-file
              v-model="imageData3"
              placeholder="Selecione Uniforme"
              drop-placeholder="Arraste..."
              browse-text="Selecione"
            ></b-form-file>

            <!-- <label><span class="text-danger"></span> Imagem Uniforme</label> -->

            <b-img
              id="img3"
              class="foto preview3 mx-3 mt-3"
              :src="form.urlImg3"
            ></b-img>
          </div>

          <!-- <div class="bloco3 col-lg-6">
            <div class="col-lg-5 mt-5">
              <b-form-group label="Uniforme">
                <b-form-radio-group
                  v-model="form.uniforme"
                  :options="options2"
                  name="radio-inline2"
                ></b-form-radio-group>
              </b-form-group>
            </div>

            <div class="form-group  mt-5">
              <label><span class="text-danger">*</span> Motivo: </label>
              <b-form-select
                v-model="form.motivoUnif"
                :options="motivoUnif"
              ></b-form-select>
            </div>
          </div> -->

        </div>

        <div class="linhaVermelha"></div>

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--Antecedentes Criminais--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-->

        <div class="tituloCoop">
          <h3>Antecedentes Criminais</h3>
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Data</label>
          <b-form-input
            id="datepicker-dateformat7"
            v-model="form.data"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat7"
            v-model="form.data"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        <div class="form-group col-lg-6">
          <label><span class="text-danger"></span> Validade</label>
          <b-form-input
            id="datepicker-dateformat8"
            v-model="form.validadeCri"
            type="date"
            placeholder="DD/MM/AAAA"
          ></b-form-input>
          <!-- <b-form-datepicker
            type="date"
            id="datepicker-dateformat8"
            v-model="form.validadeCri"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt"
          ></b-form-datepicker> -->
        </div>

        <div class="bloco1 col-lg-12">
          <div class="upload-form col-lg-6">
            <b-form-file
              v-model="imageData4"
              placeholder="Selecione antecedentes"
              drop-placeholder="Arraste..."
              browse-text="Selecione"
            ></b-form-file>

            <!-- <label><span class="text-danger"></span> Imagem Antecedentes</label> -->

            <b-img
              id="img4"
              class="foto preview3 mx-3 mt-4"
              :src="form.urlImg4"
            ></b-img>
          </div>

          <!-- <div class="bloco3 col-lg-6">
            <div class="col-lg-5 mt-5">
              <b-form-group label="Antecedentes">
                <b-form-radio-group
                  v-model="form.antecedentes"
                  :options="options3"
                  name="radio-inline3"
                ></b-form-radio-group>
              </b-form-group>
            </div>

            <div class="form-group  mt-5">
              <label><span class="text-danger">*</span> Motivo: </label>
              <b-form-select
                v-model="form.motivoAnt"
                :options="motivoAnt"
              ></b-form-select>
            </div>
          </div> -->

        </div>
      </div>

      <b-button
        class="btn_salvar col-md-2"
        type="submit"
        variant="success mb-3"
      >Salvar</b-button>

    </form>

    <!-- <b-card
      class="mt-3"
      header="Resultado do Form"
    >
      <pre class="m-0">{{ form }}</pre>
    </b-card> -->

  </div>
</template>

<script>
// import firebase from '../services/firebaseConnection';
import firebase from "firebase";
import api from "../../services/api";
import axios from "axios";

export default {
  name: "CoopCad",

  data() {
    return {
      titulo: "Novo Cooperado",


      imageData1: null,
      uploadValue1: 0,

      imageData2: null,
      uploadValue2: 0,

      imageData3: null,
      uploadValue3: 0,

      imageData4: null,
      uploadValue4: 0,

      file1: null,
      status: [
        { text: "Selecione", value: null },
        "Novo",
        "Ativo",
        "Inativo",
        "Bloqueado",
        "Pendente"
      ],
      
      regioes: [
        'Norte', 'Nordeste', 'Sul', 'Sudeste', 'Centro-Oeste'
      ],


      situacaoCooperativa: [{ text: "Cooperado", value: 3 }, { text: "Pré-Cadastro", value: 4 }],

      bancos: [{ text: "Bradesco", value: 237 }, { text: "Itaú", value: 547 }],

      funcoes: [],

      tipoPagto: [
        "CONTA CORRENTE BRADESCO",
        "CONTA POUPANÇA BRADESCO",
        "DOC CORRENTE",
        "DOC POUPANÇA",
        "CHEQUE",
        "CONTA SUPER - SANTANDER",
        "SEM CONTA"
      ],

      motivoAtes: [{ text: "Selecione", value: null }, "Já foi preso", "157"],

      motivoUnif: [
        { text: "Selecione", value: null },
        "Uniforme Rasgado",
        "Uniforme sujo"
      ],

      motivoAnt: [
        { text: "Selecione", value: null },
        "Ilegível",
        "Faltando pedaço"
      ],

      sexo: [{ text: "Selecione", value: null }, "Masculino", "Feminino"],

      form: {
        gestor: false,
        checkinManualApp: false,
        cooperativa: null,
        nome: "",
        dataNasc: "",
        cpf: "",
        matricula: null,
        status: null,
        nomeMae: "",
        nomePai: "",
        rg: "",
        digito: "",
        dataExp: "",
        sexo: null,
        funcao1: null,
        funcao2: null,
        funcao3: null,
        funcao4: null,
        funcao5: null,
        funcao6: null,
        funcao7: null,
        funcao8: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cep: null,
        cidade: null,
        regiao: null,
        uf: null,
        email: null,
        telefone1: null,
        tipoPagto: null,
        motivoAtes: null,
        motivoUnif: null,
        motivoAnt: null,
        dataEmissao: null,
        validade: null,
        dataEmissao2: null,
        validadeUnif: null,
        data: null,
        validadeCri: null,
        urlImg1: null,
        urlImg2: null,
        urlImg3: null,
        urlImg4: null
      },

      selected: "atestado",
      options1: [
        { text: "Reprovar Atestado", value: "repAtestado" },
        { text: "Aprovar Atestado", value: "aprovAtestado" }
      ],

      selected2: "uniforme",
      options2: [
        { text: "Reprovar Uniforme", value: "repUniforme" },
        { text: "Aprovar Uniforme", value: "aprovUniforme" }
      ],

      selected3: "antecedentes",
      options3: [
        { text: "Reprovar Antecedentes", value: "repAnt" },
        { text: "Aprovar Antecedentes", value: "aprovAnt" }
      ],
      show: true
    };
  },

  async created() {
    this.getBancos();
    const cooperado = await localStorage.getItem("cooperado");

    if (cooperado) {
      this.titulo = "Editar Cooperado";
      this.form = JSON.parse(cooperado);
    }

    await this.getFuncoes()
    await this.getFuncoesDoProfissional()
    await this.getRegioes()
  },

  methods: {

    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },

    getBancos() {
      api
        .get("bancos")
        .then(res => {
          this.bancos = res.data;
        })
        .catch(err =>
          this.msg("ERRO AO BUSCAR BANCOS", err.response.data, "danger")
        );
    },

    async getRegioes() {

      const res = await api.get("webadmin/regioes")
      this.regioes = res.data

    },

   async getFuncoes() {

      try {

        const res = await api.get("funcoes")
        this.funcoes = res.data;
        // console.log('RESULT', res.data)
        
      } catch(error) {
        this.msg("ERRO AO BUSCAR FUNÇÕES", error.response.data, "danger") 
      }

    },

    async getFuncoesDoProfissional() {

      try {

        const res = await api.get(`webadmin/cooperados/funcoes/${this.form.cooperativa}/${this.form.matricula}`)
        this.form.funcao1 = res.data.funcao1;
        this.form.funcao2 = res.data.funcao2;
        this.form.funcao3 = res.data.funcao3;
        this.form.funcao4 = res.data.funcao4;
        this.form.funcao5 = res.data.funcao5;
        this.form.funcao6 = res.data.funcao6;
        this.form.funcao7 = res.data.funcao7;
        this.form.funcao8 = res.data.funcao8;

        // console.log('funcoes',res.data)
        
      } catch(error) {
        this.msg("ERRO AO BUSCAR FUNÇÕES DO PROFISSIONAL", error.response.data, "danger")
      }

    },

    tratarNomeImg(nomeImg) {
      const nome = nomeImg.split(".");
      const agora = Math.floor(Date.now());

      return agora + "." + nome[1];
    },

    onUpload1(coopMatricula) {
      if (!this.imageData1) return

      this.form.urlImg1 = null;
      const nomeImg = this.tratarNomeImg(this.imageData1.name);
      const storageRef = firebase
        .storage()
        .ref(`fotos_perfil/${nomeImg}`)
        .put(this.imageData1);

      storageRef.on(
        `state_change`,
        snapshot => {
          this.uploadValue1 =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log("upload1", error.message);
        },
        () => {
          this.uploadValue1 = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.form.urlImg1 = url;
            // console.log("URL", url);
            this.updateUrl(coopMatricula, "url1", url);
          });
        }
      );
    },

    onUpload2(coopMatricula) {
      if (!this.imageData2) return

      this.form.urlImg2 = null;
      const nomeImg = this.tratarNomeImg(this.imageData2.name);
      const storageRef = firebase
        .storage()
        .ref(`atestados/${nomeImg}`)
        .put(this.imageData2);

      storageRef.on(
        `state_change`,
        snapshot => {
          this.uploadValue2 =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue2 = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.form.urlImg2 = url;
            // console.log("URL", url);
            this.updateUrl(coopMatricula, "url2", url);
          });
        }
      );
    },

    onUpload3(coopMatricula) {
      if (!this.imageData3) return

      this.form.urlImg3 = null;
      const nomeImg = this.tratarNomeImg(this.imageData3.name);
      const storageRef = firebase
        .storage()
        .ref(`uniformes/${nomeImg}`)
        .put(this.imageData3);

      storageRef.on(
        `state_change`,
        snapshot => {
          this.uploadValue3 =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue3 = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.form.urlImg3 = url;
            // console.log("URL", url);
            this.updateUrl(coopMatricula, "url3", url);
          });
        }
      );
    },

    onUpload4(coopMatricula) {
      if (!this.imageData4) return

      this.form.urlImg4 = null;
      const nomeImg = this.tratarNomeImg(this.imageData4.name);
      const storageRef = firebase
        .storage()
        .ref(`antecedentes_criminal/${nomeImg}`)
        .put(this.imageData4);

      storageRef.on(
        `state_change`,
        snapshot => {
          this.uploadValue4 =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue4 = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.form.urlImg4 = url;
            // console.log("URL", url);
            this.updateUrl(coopMatricula, "url4", url);
          });
        }
      );
    },

    updateUrl(coopMatricula, origemUrl, url) {
      coopMatricula.origem = origemUrl;
      coopMatricula.url = url;

      api
        .put("webadmin/cooperados/url-img", coopMatricula)
        .then(() => {
          this.msg("UPLOAD " + origemUrl, " CONCLUIDO COM SUCESSO.", "success");
        })
        .catch(err => this.msg("ERRO UPLOAD", err.response.data, "danger"));
    },

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    onSubmit(evt) {
      evt.preventDefault();

      if (this.form.matricula) {
        api
          .put("webadmin/cooperados", this.form)
          .then(() => {
            // console.log(res.data);
            this.msg("COOPERADO", "ATUALIZADO COM SUCESSO.", "success");

            const coopMatricula = {
              cooperativa: this.form.cooperativa,
              matricula: this.form.matricula
            };

            this.onUpload1(coopMatricula);
            this.onUpload2(coopMatricula);
            this.onUpload3(coopMatricula);
            this.onUpload4(coopMatricula);
          })
          .catch(err =>
            this.msg("ERRO AO ATUALIZAR COOPERADO", err.response.data, "danger")
          );
      } else {
        api
          .post("webadmin/cooperados", this.form)
          .then(res => {
            this.form.matricula = res.data.matricula;
            this.msg("COOPERADO", "CADASTRADO COM SUCESSO.", "success");
            this.onUpload1(res.data);
            this.onUpload2(res.data);
            this.onUpload3(res.data);
            this.onUpload4(res.data);
            // this.$router.push("/coopList");
          })
          .catch(err =>
            this.msg("ERRO AO CADASTRAR COOPERADO", err.response.data, "danger")
          );
      }
    },

    buscarCep() {
      console.log("cep", this.form.cep);
      if (this.form.cep.length == 9) {
        axios
          .get(`https://viacep.com.br/ws/${this.form.cep}/json`)
          .then(res => {
            console.log(res.data);

            const dadosCep = res.data;
            this.form.endereco = dadosCep.logradouro;
            this.form.bairro = dadosCep.bairro;
            this.form.cidade = dadosCep.localidade;
            this.form.uf = dadosCep.uf;
          })
          .catch(err => this.msg("Erro: cep não encontrado.", err, "danger"));
      }
    }
  },


};
</script>


<style scoped>
.upload-form {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px;
}

img.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}

img.foto {
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}

.row_status_button {
  margin-top: 40px;
  /* margin-bottom: 30px; */
}

.status {
  /* float: left; */
  margin-top: 15px;
}

#fileImageAtestado {
  margin: 0;
  padding: 0;
}

#coopCad {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 45px;
  background-color: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border: solid 1px #cccc;
  padding: 20px;
  margin-top: 25px;
  margin-left: 5%;
  width: 90%;
}

@media (max-width: 480px) {
  #coopCad {
    width: 100%;
    padding: 10px;
    margin-left: 0;
    border: solid 1px rgba(250, 248, 248, 0.8);
  }
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 15px;
}

.bloco1 {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 480px) {
  .bloco1 {
    width: 100%;
    flex-direction: column;
  }
}

.tituloCoop {
  width: 100%;
  float: left !important;
  margin-top: 10px;
  margin-bottom: 25px;
}

.titulo2 {
  display: flex;
  margin-right: 0;
}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

label {
  /* float: left; */
  display: inline-block;
  margin-bottom: 0.5rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

label {
  display: flex;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-6,
.col-md-1 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.form-group {
  margin-bottom: 1rem;
}

.page-informacoes {
  margin-bottom: 25px;
}

.btn_salvar {
  float: left;
  /* margin-top: 50px;       */
  margin-bottom: 50px;      
  width: 250px;
}

.img_perfil {
  margin-top: -4rem;
}

@media (max-width: 480px) {
  .img_perfil {
    margin-top: -4rem;
    
  }
}

.cad-foto {
  margin-top: 15px;
  margin-right: 0%;
}

@media (max-width: 480px) {
  .cad-foto  {
    width: 27%;
    margin-left: 37%;
  }
}

.checkbox_gestor {
  margin-top: 0px;
  float: right;
}

.b-avatar {
  margin-right: 0%;
}

@media (max-width: 480px) {
  .b-avatar  {
    margin-right: 2%;
  }
}


.custom-file-label::after {

  width: 300px;
  display: inline;

}


</style>
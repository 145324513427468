<template>
  <div id="verEscala" class="contorno">
    <h2 class="titulo2">Visualizar Escala</h2>
    <b-button class="btnRight" variant="warning" link to="/solicitacoes"> Voltar</b-button>

    <hr>

    <div class="form-row">
      <div class="col-lg-12">
        <b-jumbotron>

          <hr class="my-4">

          <div class="info-solicitacao col-lg-6">
              <p>
                CLIENTE: <strong>{{topo.cliente}}</strong>
              </p>
              <p>
                SOLICITANTE: <strong>{{topo.solicitante}}</strong>
              </p>
              <p>
                DATA: <strong>{{topo.dataEvento}}</strong>
              </p>
              <p>
                SETOR: <strong>{{topo.nomeEvento}}</strong>
              </p>
              <p>
                COMENTÁRIO: <strong>{{topo.obs}}</strong>
              </p>
            </div>

            <div class="info-solicitacao2 col-lg-6">
              <p>
                FUNÇÃO: <strong>{{topo.funcao}}</strong>
              </p>
              <p>
                ENTRADA: <strong>{{topo.entrada}}</strong>
              </p>
              <p>
                SAIDA: <strong>{{topo.saida}}</strong>
              </p>
              <p>
                STATUS: <strong>{{topo.status}}</strong>
              </p>
              <p>
                GESTOR ATUAL: <strong>{{topo.gestor}}</strong>
              </p>
            </div>

        </b-jumbotron>
      </div>
    </div>
    <div class="linhaVermelha"></div>


    <b-jumbotron bg-variant="light">
      <div class="tituloCoop">
        <h3>Escala</h3>
      </div>
      <table class="table-responsive  table-striped table">
        <thead>
        <tr>
          <th scope="col">Profissional</th>
          <th scope="col">Check-in</th>
          <th scope="col">Check-out</th>
          <th scope="col">Normal</th>
          <th scope="col">Extra</th>
          <th scope="col">Noturno</th>
          <th scope="col">Extranot</th>
          <th scope="col">Avaliação</th>
          <th scope="col">Motivo</th>
          <th scope="col">Ver cooperado</th>
          <th scope="col">Carga Horária</th>

          <!-- <th scope="col">Carga Horária</th> -->
        </tr>
        </thead>

        <tbody>
        <tr v-for="item in items" :key="item.id">

          <td>{{ item.nome }}</td>
          <td>{{ item.checkIn }}</td>
          <td>{{ item.checkOut }}</td>
          <td>{{ item.horaNormal }}</td>
          <td>{{ item.horaExtra }}</td>
          <td>{{ item.horaNoturna }}</td>
          <td>{{ item.horaExtraNoturna }}</td>

          <td>
            <b-form-rating v-model="item.avaliacao" variant="warning" class="mb-2" readonly></b-form-rating>
          </td>
          <td>{{ item.motivo }}</td>

          <td>
            <b-button variant="outline-info btn-sm" class="mr-2" @click="visualizar(item.matricula)">
              Visualizar
            </b-button>
          </td>
          <td>
            <b-button variant="outline-info btn-sm" class="mr-2" @click="showModalCargaHoraria(item)">
              Editar
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-jumbotron>



    <div>
        <b-modal id="modal-edit-qtd" ref="modal-edit-qtd" hide-footer title="Informar a quantidade da cortesia">
          <div class="d-block text-center">
              <!-- <h3>Você deseja alterar a quantidade?</h3> -->
          </div>
          <div>
            <b-row>
              <b-col sm="12">
                <b-form-group label-cols="12" label-cols-lg="12" label="" label-for="input-qtd-atualaliza">
                  <b-form-input  type="number" min="1" autofocus v-model="cortesiaQtd"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-button class="mt-3" variant="outline-success" block @click="editarCortesiaQtd">Confirmar</b-button>
          <!-- <b-button class="mt-2" variant="outline-danger" block @click="hideModalEditarQtd">Cancelar</b-button> -->
        </b-modal>
    </div>
    
    <div>
        <b-modal ref="modal-carga-edit" hide-footer title="Editar Carga Horária" @change="calcularValorTotal">
            
            <div>
              <label><span class="text-danger"></span> Profissional </label>
              <b-form-input disabled="true" class="background-input" v-model="cargaHoraria.nome"></b-form-input>
            </div>

            <br>

            <div>
              <label><span class="text-danger"></span> Horas </label>
            </div>

            <br>

            <b-row>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Normal </label>
                <b-form-input type="number" v-model="cargaHoraria.horaNormal" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Extra </label>
                <b-form-input type="number" v-model="cargaHoraria.horaExtra" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Noturno </label>
                <b-form-input type="number" v-model="cargaHoraria.horaNoturna" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3 label-modal">
                <label><span class="text-danger"></span> Noturno Extra </label>
                <b-form-input type="number" v-model="cargaHoraria.horaExtraNoturna" @change="calcularValorTotal"></b-form-input>
              </div>

            </b-row>

            <br>

            <div>
              <label><span class="text-danger"></span> Valores </label>
            </div>

            <br>

            <b-row class="row-height">

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Normal </label>
                <b-form-input v-model="cargaHoraria.valorHoraNormal" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Extra </label>
                <b-form-input v-model="cargaHoraria.valorHoraExtra" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <label><span class="text-danger"></span> Noturno </label>
                <b-form-input v-model="cargaHoraria.valorHoraNoturna" @change="calcularValorTotal"></b-form-input>
              </div>

              <div class="form-group col-lg-3 label-modal">
                <label><span class="text-danger"></span> Noturno Extra</label>
                <b-form-input v-model="cargaHoraria.valorHoraExtraNoturna" @change="calcularValorTotal"></b-form-input>
              </div>
              
            </b-row>
            
            <hr>

             <div class="valor-final">
                <label><span class="text-danger"></span>Valor Total: {{cargaHoraria.valorTotal}} </label>
             </div>

            <b-row>
              
              <div class="form-group col-lg-6">
                <b-button class="mt-2" variant="outline-danger" block @click="hideModalCargaHoraria">Cancelar</b-button>
              </div>
              
              <div class="form-group col-lg-6">
                <b-button class="mt-2" variant="outline-success" block @click="updateCargaHoraria">Salvar</b-button>
              </div>
            
            </b-row>
 
        </b-modal>
    </div>

    <b-row class="col-lg-10">
      
      <b-button
          type="submit"
          variant="success m-3"
          class="col-lg-3"
          link to="/solicitacoes/verEscala/addProfissionais"
      >Adicionar Profissionais
      </b-button>

      <b-button 
          variant="warning m-3" 
          class="col-lg-3"
          @click="showModalEditQtd"
      >Gerenciar Cortesias
      </b-button>

      <b-button 
          variant="info m-3"
          class="col-lg-3"
          link to="/solicitacoes/verEscala/contestacoes"
      >Contestações
      </b-button>

    </b-row>

  </div>
</template>

<script>

import api from "../../services/api";


export default {

  data() {
    return {
      // items: [],

      topo: {},

      items: [],
      fields: [],

      cortesiaQtd: null,

      cargaHoraria: {
        
        id: null,
        nome: null,
        horaNormal: null,
        horaExtra: null,
        horaNoturna: null,
        horaExtraNoturna: null,
        valorHoraNormal: null,
        valorHoraExtra: null,
        valorHoraNoturna: null,
        valorHoraExtraNoturna: null,
        valorTotal: null

      },



    }
  },
  created() {

    this.getProfs()

  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 7000,
        appendToast: true
      })
    },

    showModalEditQtd() {
        this.$refs['modal-edit-qtd'].show()
    },
    hideModalEditarQtd() {
        this.$refs['modal-edit-qtd'].hide()
    },

    getCortesiaQtd() {

      api.get('webadmin/solicitacoes/cortesias/'+this.topo.id)
        .then(res => {
          console.log('asasas',res.data)
        this.cortesiaQtd = res.data
      })
      .catch(err => this.msg("ERRO AO BUSCAR A QUANTIDADE DA CORTESIA", err.response.data, "danger"));

    },

    editarCortesiaQtd() {

      this.$refs['modal-edit-qtd'].hide()

      const body = {
        idEscala: this.topo.id,
        qtdCortesia: this.cortesiaQtd
      }

      api.put('webadmin/solicitacoes/cortesias', body)
      .then(() => {
        this.msg("CORTESIA ", "INFORMADA COM SUCESSO.", "success");
      })
      .catch(err => {
        
        if (err.response.status == 400) {
          this.msg("ATENÇÃO", err.response.data, "warning")
          return
        }

        this.msg("ERRO AO INFORMAR A CORTESIA", "Consulte a Eventos-SP", "danger")
      });

    },

    visualizar(matricula) {

      api
        .get(`webadmin/cooperados-filtro/matricula/${matricula}`)
        .then(res => {
          
          const cooperado = JSON.stringify(res.data[0]);
          localStorage.setItem("cooperado", cooperado);
          this.$router.push("/coopView");
        })
        .catch(err => {
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });
    },

     showModalCargaHoraria(data) {
            // console.log('kkkkkkkkkkkkkkkkkkkkkk',data)
            // console.log('bbbbbbbbbbbbbb', this.cargaHoraria)
    
            
            this.cargaHoraria.id = data.id
            this.cargaHoraria.nome = data.nome
            this.cargaHoraria.horaNormal = data.horaNormal
            this.cargaHoraria.horaExtra = data.horaExtra
            this.cargaHoraria.horaNoturna = data.horaNoturna
            this.cargaHoraria.horaExtraNoturna = data.horaExtraNoturna
            this.cargaHoraria.valorHoraNormal = data.valorHoraNormal
            this.cargaHoraria.valorHoraExtra = data.valorHoraExtra
            this.cargaHoraria.valorHoraNoturna = data.valorHoraNoturna
            this.cargaHoraria.valorHoraExtraNoturna = data.valorHoraExtraNoturna

            // console.log(data)
            // console.log('bbbbbbbbbbbbbb', this.cargaHoraria)
            if(this.topo.status == 'em contestação' || this.topo.status == 'em aprovação' || this.topo.status == 'em avaliação'){
              this.$refs['modal-carga-edit'].show()
            } else {
              this.msg("Não foi possível editar a carga horária", "O evento ainda não foi encerrado", "danger");
            }
        },
        hideModalCargaHoraria() {
            this.$refs['modal-carga-edit'].hide()
        },

      calcularValorTotal(){
        
        const valorNormal = parseFloat(this.cargaHoraria.valorHoraNormal.split(',').join('.'))
        const valorExtra = parseFloat(this.cargaHoraria.valorHoraExtra.split(',').join('.'))
        const valorNoturno = parseFloat(this.cargaHoraria.valorHoraNoturna.split(',').join('.'))
        const valorExtraNotuno = parseFloat(this.cargaHoraria.valorHoraExtraNoturna.split(',').join('.'))
        
        let valorTotal = (valorNormal * this.cargaHoraria.horaNormal) + 
                         (valorExtra * this.cargaHoraria.horaExtra) + 
                         (valorNoturno * this.cargaHoraria.horaNoturna) + 
                         (valorExtraNotuno * this.cargaHoraria.horaExtraNoturna) 

        console.log(valorTotal)
         
        this.cargaHoraria.valorTotal = valorTotal.toFixed(2).toString().split('.').join(',')

      },

      getProfs(){
        const solicitacao = localStorage.getItem('solicitacao')
        this.topo = JSON.parse(solicitacao)
        // console.log('solicitacao',solicitacao)
        // console.log(this.topo)

        api.get('webadmin/solicitacoes/escalados/'+this.topo.id)
            .then(res => {

              // console.log('ssssssssssssssssss',res.data);
              this.items = res.data;
            })
            .catch(err => console.log(err))

        this.getCortesiaQtd()
      },

      updateCargaHoraria() {

        api
        .put('webadmin/solicitacoes/carga-horaria', this.cargaHoraria)
        .then(() => {
          // console.log(res)
          // const cooperado = JSON.stringify(res.data[0]);
          // localStorage.setItem("cooperado", cooperado);
          // this.$router.push("/coopView");
          
          this.msg("Salvo com sucesso!", "A carga horária foi alterada.", "success");
          this.$refs['modal-carga-edit'].hide()
          this.getProfs()

        })
        .catch(err => {
          this.msg("ERRO AO BUSCAR", err.response.data, "danger")
        });

      }


  }


}
</script>


<style scoped>
h2 {
  color: brown;
}

.table-responsive {
  display: table;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 576px) {
  .table-responsive {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
  }
}

.table-striped tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

input[type=checkbox], input[type=radio] {
  cursor: pointer;
}

output {
  margin-left: -25px;
}

@media screen and (max-width: 576px) {
  output {
    margin-left: -13px;
  }
}

.btnRight {
  float: right;
  margin-top: -40px;
  margin-right: 0;
}

.label-modal {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contorno {
  border: solid 1px #ccc;
  padding: 10px;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 12px;
}

titulo2 {
  display: flex;
  margin-right: 0;
}

.background-input {
  background-color: rgb(228, 228, 228);
}

.valor-final {
  text-align: right;
}

.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-top: 5px;
}

.tituloCoop {
  width: 100%;
  float: left !important;
  margin-top: 10px;
  margin-bottom: 25px;
}

.info-solicitacao {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.info-solicitacao2 {
  font-size: 14px;
  font-weight: 500;
  float: right;
  margin-top: -11rem;
}

@media screen and (max-width: 480px) {
  .info-solicitacao2 {
    margin-top: 0;
    float: none;
  }
}

.jumbotron {
  margin-top: 1rem;
  padding: 1rem 1rem;
}

.form-row {
  display: block;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.linhaVermelha {
  width: 100%;
  border-bottom: 1px solid #f21d1d !important;
  margin-bottom: 25px;
  margin-top: 25px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: hsla(0, 0%, 100%, 0.014);
  background-clip: padding-box;
  border: 1px solid #ced4da00;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 576px) {
    .row-height {
    height: 95px;
  }
}


</style>



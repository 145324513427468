<template>
  <div id="docsPendentes">
      
    <div>
      <b-card class="card-documentacao">

      <h3 class="titulo" @click="getEscalas">Escalas solicitadas</h3>
      
        <div>
          <b-table
          id="table-docsPendentes"
          striped 
          responsive
          hover 
          :items="escalas"
          :fields="fields"
          :busy="load"
          outlined
          >

          <template v-slot:cell(aprovar)="data">       
            <b-button variant="success btn-sm" @click="aprovarOuReprovar(data.item, 'aprovado')">Aprovar</b-button>
          </template>

          <template v-slot:cell(reprovar)="data">       
            <b-button variant="warning btn-sm" @click="aprovarOuReprovar(data.item, 'reprovado')">Reprovar</b-button>
          </template>

          </b-table>
        </div>

      </b-card>
    </div>

  </div>
</template>

<script>

import api from "../../services/api";

export default {

  name: 'DocsPendentes',
  data() {
    return{

      fields: [
        {key: 'dataEvento', label: 'Data' },
        {key: 'entrada', label: 'Entrada' },
        {key: 'saida', label: 'Saída' },
        {key: 'cliente', label: 'Cliente' },
        {key: 'unidade', label: 'Unidade' },
        {key: 'setor', label: 'Setor' },
        {key: 'funcao', label: 'Função' },
        {key: 'gestor', label: 'Gestor' },
        {key: 'quantidade', label: 'Qtd' },
        {key: 'cortesia', label: 'Cortesia' },
        { key: 'aprovar', label: '', thClass: 'text-right', tdClass: 'text-right' },
        { key: 'reprovar', label: '', thClass: 'text-left', tdClass: 'text-left' }
      ], 
      
      escalas: []

    };
  },

  created() {

    this.getEscalas()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    getEscalas() {

      api.get(`webadmin/escalas-solicitadas`)
      .then(res => {

        // console.log(res.data)
        this.escalas = res.data

        if (this.escalas.length == 0)
        this.$router.push('/dashboard')

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))    
    },

    aprovarOuReprovar(item, acao) {
      
      api.put(`webadmin/escalas-solicitadas/aprovar-reprovar`, { id: item.id, acao: acao })
      .then(() => {

        this.getEscalas()
      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))       
    },

  }
}
</script>

<style scoped>
  .card-documentacao {
    margin: 40px;
  }

  .titulo {
    color: brown;
  }
</style>
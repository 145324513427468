<template>
  <div id="header">
    <b-navbar toggleable="lg" type="gradiente" class="gradiente" v-model="aberto">

      <b-navbar-brand href="#">
        <b-button variant="gradiente" v-b-toggle.sidebar-no-header>
          <b-icon icon="list"></b-icon>
        </b-button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>

          <b-nav-item link to="/">
            <img src="../pages/assets/logo.png" class="logo-eventos">
          </b-nav-item>

          <!--  <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--        <b-nav-form>-->
          <!--          <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>-->
          <!--          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>-->
          <!--        </b-nav-form>-->


          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ usuario }}</em>
            </template>
            <b-dropdown-item link to="/alterar-senha">Alterar a senha</b-dropdown-item>
            <b-dropdown-item @click="logOut">Sair</b-dropdown-item>

          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>

// import firebase from '../services/firebaseConnection';
// import api from "../services/api";
import authService from "../services/authService";

export default {
  name: 'Header',

  data() {
    return {
      aberto: true,
      usuario: '',
    }
  },
  methods: {

    async logOut() {

      authService.logout();
      
      // const confirm = window.confirm('Você realmente deseja sair?');

      // if (confirm) {
      //   authService.logout();
      //   // localStorage.removeItem('userDados')
      //   // this.$store.commit('auth/setUser' , null)
      //   // this.$store.commit('auth/toggleMenu' , false);

      //   // delete api.defaults.headers.common['Authorization']
      //   // this.$router.push('/login')
      // } else {
      //   return;
      // }

    }

  },

  mounted() {
    const local = localStorage.getItem('userDados')
    const user = JSON.parse(local)
    this.usuario = user.nome
    // const nomes = user.nome.split(' ')
    // this.usuario = nomes[0]+' '+nomes[1]
  }

}
</script>

<style scped>
h2 {
  color: brown;
}

.gradiente {
  /*background: #9cecfb;*/
  /*background: -webkit-linear-gradient(to right, #9cecfb, #65c7f7, #0052d4);*/
  background: #555555;
}

.btn-gradiente {
  color: #fff;
  background-color: #fc129a;
  border-color: #343a40;
}

.btn-gradiente:focus, .btn-gradiente.focus {
  color: #fff;
  background-color: #4C4C4C;
  border-color: #4C4C4C;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-gradiente {
  display: inline-block;
  font-weight: 400;
  color: #fafcff !important;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #db3737;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover {
  color: #fdfdfd;
  text-decoration: none;
}

.logo-eventos {
  width: 20%;
  display: flex;
}



/*.gradiente {*/
/*  background: #DA4453;*/
/*  !*background: -webkit-linear-gradient(to top, #89216B, #DA4453);*!*/
/*  background: linear-gradient(to top, #DA4453, #b11a59 );*/
/*}*/


</style>



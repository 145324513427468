<template>
  <div id="page-login">
    <main>

      <!-- <div class="loginArea" v-if="login">
        <img src="../../src/assets/logo-eventos.png" class="logo-eventos" alt="EventosSP">
      </div> -->

      <div class="loginArea" v-if="login">
        <img src="../../pages/assets/logo.png" class="logo-eventos" alt="EventosSP">
        <span class="text-branco text-bem-vindo">Bem-vindo</span>
        <form @submit.prevent="signIn">
          <input type="text" placeholder="seu e-mail..." v-model="email" />
          <input type="password" placeholder="sua senha..." v-model="senha" />
          <button type="submit" :class="{disabled: validating}">{{textLogin}}</button>
        </form>
        <a @click="toggleTelaLogin">Esqueci minha senha</a>
      </div>

      <div class="loginArea" v-else>
        <h1 class="text-branco">Enviar senha</h1>
        <form @submit.prevent="esqueciSenha">
          <input type="text" placeholder="digite seu e-mail..." v-model="email" />
          <button type="submit">Enviar e-mail</button>
        </form>
        <a @click="toggleTelaLogin">Lembrei da senha</a>
      </div>
    </main>
  </div>
</template>




<script>
import api from "../../services/api";
// import { msg } from '@/global'

export default {
  name: "Login",

  data() {
    return {
      email: null,
      senha: null,
      validating: false,
      login: true,
      textLogin: 'Acessar'
    };
  },

  methods: {


    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },
    

    toggleTelaLogin() {
        // this.$store.commit("toggleMenu", false);
        this.$store.commit('auth/toggleMenu', false)

        this.login = !this.login
        this.email = null
        this.password = null
    },


    async signIn() {

      this.textLogin = 'Autenticando...'

      const user = {
        email: this.email,
        senha: this.senha,
      }

      api.post('signin/web-admin', user)
      .then(async res => {

        localStorage.setItem('userDados', JSON.stringify(res.data))
        this.$store.commit('auth/setUser' , res.data)
        this.$store.commit('auth/toggleMenu' , true)

        api.defaults.headers.common['Authorization'] = 'bearer ' +  res.data.token
        
        this.$router.push('/')
        await new Promise(r => setTimeout(r, 100));
        this.$router.push({ name: 'Dashboard' })
      })
      .catch( err => {
        localStorage.removeItem('userDados')
        this.textLogin = 'Acessar'
        if (err.response)  
          this.msg("ACESSO NÃO PERMITIDO", err.response.data, "danger")
        else
          this.msg("ACESSO INDISPONÍVEL", "SERVIÇO INDISPONÍVEL NO MOMENTO.", "danger")

      })

    },

    async esqueciSenha() {

        // this.$store.commit("auth/setLoading", true);
        this.textLogin = 'Enviando...'
        this.validating = false

        const user = {
            email: this.email
        };


        api.post("web-admin/recuperar-senha", user)
        .then(() => {

          this.msg("Mensagem enviada", 'Verifique seu e-mail.', "success")
          this.toggleTelaLogin()
            
        })
        .catch((error) => {
            this.msg(error.response.data, 'vermelho', 5000)
            return;
        });

    },


  },
};
</script>




<style scoped>
#page-login {
  background: #555555;
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 100vh;
}

/* aside {
  background: url("../../assets/eve.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
} */

.fundo-aside {
  height: 100%;
  width: 100%;
  background: #17a2b8;
  opacity: 0.8;
}

main {
  /* background: cyan; */
  height: 100vh;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.tela {
  height: 300px;
  width: 300px;
  background: darkgoldenrod;
}

.loginArea {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 100px;
  max-width: 600px;
  width: 420px;
  height: 330px;
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
}

input {
  margin-bottom: 15px;
  height: 40px;
  width: 300px;
  font-size: 18px;
  padding: 10px;
  outline: none;
  border: 0;
  border-radius: 5px;
  background: rgb(241, 241, 241);
}

button {
  margin-top: 10px;
  height: 40px;
  border: 0;
  border-radius: 5px;
  background: #E7B864;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}

.loginArea a {
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

img {
  height: 50px;
}

.text-branco {
  color: #fff;
  font-size: 22px;
}

.text-bem-vindo {
  margin-bottom: 20px;
}

.logo-eventos {
  width: auto;
  height: auto;
  width: 80%;
  margin-bottom: 25px;  
  display: flex;

}

</style>

<template>
  <div id="dashboard">
    
    <div class="row">

      <b-card class="card" @click="toDocsPendentes">
        <b-card-text class="docs-pendentes centralizado">{{numeroDeCooperados}}</b-card-text>  
        <b-card-text class="centralizado">Profissionais com documentação pendente</b-card-text>
      </b-card>

      <b-card class="card" @click="toEscalasSolicitadas">
        <b-card-text class="docs-pendentes centralizado">{{numeroDeSolicitacoes}}</b-card-text>  
        <b-card-text class="centralizado">Escalas solicitadas por Gestores</b-card-text>
      </b-card>

    </div>

  </div>
</template>

<script>

import api from "../../services/api";

export default {
  name: "dashboard",

  data() {
    return {

      numeroDeCooperados: '0',
      numeroDeSolicitacoes: '0'

    };
  },

  created() {

    this.getDadosDashboard()
  },

  methods: {

    msg(titulo, texto, cor) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: 5000,
        appendToast: true
      });
    },

    getDadosDashboard() {

      api.get(`webadmin/dashboard`)
      .then(res => {
        // console.log(res.data)
        this.numeroDeCooperados = res.data.cooperados
        this.numeroDeSolicitacoes = res.data.escalasSolicitadas

      })
      .catch(err => this.msg(err.response.data, 'vermelho', 5000))
    
    },


    toDocsPendentes(){
      if (this.numeroDeCooperados > 0) 
        this.$router.push('/coopDocsPendentes')
      else 
        this.msg('Atenção', 'Não há cooperados com documentações pendentes no momento.', 'warning')
    },

    toEscalasSolicitadas(){
      if (this.numeroDeSolicitacoes > 0) 
        this.$router.push('/escalas-solicitadas')
      else 
        this.msg('Atenção', 'Não há solicitações pendentes no momento.', 'warning')
    },

    

  }

};
</script>





<style scoped>
  .docs-pendentes {
    font-size: 40px;
  }

  .centralizado {
    text-align: center;
    cursor: pointer;
  }

  .card {
    max-width: 10rem;
    margin: 40px;
    margin-right: 10px;
  }
</style>


